import React,{useEffect} from 'react'
import './seventh.styles.scss'
import gsap from 'gsap'
import {ScrollTrigger} from 'gsap/ScrollTrigger'

const Addspace = ()=>{
    let mql = window.matchMedia('(min-width: 992px)');
    if(mql.matches){
        return(
            <div className="section container">
            </div>
        )
    }else{
        return null
    }
}

const SeventhPages = ()=>{
    return(
        <React.Fragment>
        <div className="seven section container">
            <div className="content7">
                <div className="techStack">
                    <h1 className="tech">&nbsp;&nbsp;&nbsp;TECH</h1>
                    <h1 className="bridan stack">STACK
                    <svg viewBox="0 0 129.43 97.95" id="Sdown">
                                <defs>
                                    <clipPath id="prefix__a" transform="translate(-24.04 -16.7)">
                                        <path transform="rotate(-9.08 82.302 60.776)" fill="none"
                                            d="M6.87 11.28h150.75v99H6.87z" />
                                    </clipPath>
                                </defs>
                                <title>{"Asset 5"}</title>
                                <g data-name="Layer 2">
                                    <g clipPath="url(#prefix__a)" data-name="Layer 1">
                                        <path id="arrowYellowDown"
                                            d="M48.26 56.79h.08l-.18.14s.09-.08.1-.11zM76.49 1.48c-.13 1.15-1 2.68.66 3.59 0 .25.08.49.13.73a1.63 1.63 0 01-1 1.57 2.27 2.27 0 00-1.85 2.17c-1.2 4.63.26 7.92 4.42 10.48 3.87 2.37 7.38 5.36 11.11 8.12-.43.39-.52.55-.64.57-1.14.18-2.29.38-3.44.51a95.36 95.36 0 00-30.42 9c-20 9.53-36.14 24-50.11 41-1.59 1.92-3.71 3.92-2.82 7 .12.44-.49 1.18-.91 1.68C.24 89.55.2 91.55.1 93.54c-.08 1.52-.34 3.45 1 4.16 1.79 1 3-.89 4.07-2.06C16.04 84.19 27.75 73.7 40.96 65.01c7-3.65 14-7.31 20.66-11.62l12.58-3.72 1.94-.3 5-.37c3.35-.84 6.61-1.35 10.08.1 1.67.69 3.88.09 5.84 0a2.59 2.59 0 01.42.58 1.5 1.5 0 011.6.74c-1.54 1-3.11 1.9-4.61 2.91-7 4.71-6.94 4.72-9.5 12.24-.77 2.3-.45 6.55.7 7.1 2.16 1 3.84-.56 5.53-1.44q14.82-7.72 28.89-16.73c2.92-1.87 5.38-3.86 7.06-7.25 1.51-3 1.73-5.77 2-8.93.65-9 .48-9.83-7.25-13.57A358.48 358.48 0 0184.61 3.6 115.22 115.22 0 0079.06.24c-1.31-.72-2.47-.25-2.61 1.15z"
                                            fill="transparent" style={{
                                        stroke:' #fedb00',
                                        fill:' #fedb00',
                                        strokeWidth:"3px"
                                    }} />
                                    </g>
                                </g>
                            </svg>
                    </h1>
                </div>
                <div className="box box2">
                    <div className="imgBox">
                    <img src="https://i.ibb.co/XYPzxB4/answerthepublic-com.png" alt="" srcset=""/>
                    </div>
                    <p>Answer The Public is a
                        keyword tool that visualize
                        search questions and
                        suggested auto-complete
                        searches on Google, giving
                        us a better understanding of
                        a brand's target audience on
                        Google so we can reach out
                        to them in a more
                        personalized and relevant
                        manner.</p>
                </div>
                <div className="box box2">
                    <div className="imgBox">
                    <img src="https://i.ibb.co/JQY74FW/2020-09-08-16-31.png" alt="" srcset=""/>
                    </div>
                    <p>
                    Geestacke ideates and
                    deploys digital gaming
                    concepts that motivates
                    voluntary participation
                    providing brands with a
                    framework that target-seek
                    answers that helps business
                    decisions without the
                    investment of costly
                    infrastructure.
                    </p>
                </div>
                <div className="box box2">
                    <div className="imgBox">
                    <img src="https://www.samuraidr.com/wp-content/uploads/2019/04/GMP-logo.jpg" alt="" srcset=""/>
                    </div>
                    <p>
                        A unified advertising and
                        analytics platform for
                        smarter marketing and
                        better results by allowing us
                        to make deeper customer
                        connections. It allows us to
                        understand brand's audience
                        behavior and viewer journey
                        deeper and pass those
                        habits back to our Google
                        Ad Manager in a seamless
                        manner.
                    </p>
                </div>
                <div className="box box2">
                    <div className="imgBox">
                    <img src="https://asset-a.grid.id/crop/0x0:0x0/700x465/photo/2019/10/29/3804208061.jpg" alt="" srcset=""/>
                    </div>
                    <p>
                    A search trend feature that
                    shows how frequently a
                    given search term total
                    search volume over a given
                    period of time. This will be
                    used for comparative
                    keyword research and to
                    discover event-triggered
                    spikes on how and what
                    brand's audience and
                    brand's competitors are
                    searching for on Google.
                    </p>
                </div>

                <div className="box box2">
                    <div className="imgBox">
                    <img src="https://sm.pcmag.com/t/pcmag_uk/review/h/hubspot-cr/hubspot-crm_qkhf.640.jpg" alt=""/>
                    </div>
                    <p>
                    A CRM cloud software that
                    is used for all our re-
                    targeting and email
                    notifications for our new and
                    current subscriber base. It
                    allows seamless integration
                    with our Google Marketing
                    Platform to ensure
                    maximum efficiency and
                    productivity.
                    </p>
                </div>
                <div className="box box2">
                    <div className="imgBox">
                    <img src="https://i.ibb.co/dLMZZVv/2020-09-08-16-30.png" alt=""/>
                    </div>
                    <p>
                    ON-THE-GO "LIVE"
                    Dashboard that unifies
                    metrics in a single
                    scroll for better
                    visualization of facts
                    that matter most to
                    your business.
                    </p>
                </div>
                <div className="box box2">
                    <div className="imgBox">
                    <img src="https://financesonline.com/uploads/2020/06/Talkwalker-logo1.png" alt=""/>
                    </div>
                    <p>
                    Talkwalker is a social listening and monitoring tool that will be used to understand what and how our audience perceives brand (and its competitors) in the online landscape. 
It provides us with psychographics and sentiments for us to analyse on this set of audience. This will then be used for brand’s marketing, content and features optimization (and verification).
                    </p>
                </div>
            </div>
        </div>
        {/* <Addspace></Addspace> */}
        </React.Fragment>


    )
}

export default SeventhPages