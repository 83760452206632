import React,{useEffect} from 'react'
import './third.styles.scss'
import gsap from 'gsap'
import {ScrollTrigger} from 'gsap/ScrollTrigger'

const ThirdPages = ()=>{

    const animation = ()=>{
        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: ".third",
                pin: true,   // pin the trigger element while active
                start: "top top", // when the top of the trigger hits the top of the viewport
                end: `+=400`, // end after scrolling 500px beyond the start
                scrub: 1, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar,
                // markers:true,
                // snap: {
                //   snapTo: "labels", // snap to the closest label in the timeline
                //   duration: {min: 0.2, max: 3}, // the snap animation should be at least 0.2 seconds, but no more than 3 seconds (determined by velocity)
                //   delay: 0.2, // wait 0.2 seconds from the last scroll event before doing the snapping
                //   ease: "power1.inOut" // the ease of the snap animation ("power3" by default)
                // }
              }
        })
        tl.from('.wegather',{opacity:0.2,y:"-10px"})
        .from('.tb',{opacity:0.2,y:"-10px"})

        .fromTo('#arrowUp',{
            drawSVG:'0%'
        },{
            drawSVG:'100%',
        }).to('#arrowUp',{
            fill:'black'
        })

        .fromTo('#lovelove',{
            drawSVG:'0% 0%'
        },{
            drawSVG:'20% 40%'
        }).to("#lovelove",{
            fill:'#fedb00'
        })
    }
    const mediaquery = ()=>{
        let mql = window.matchMedia('(min-width: 992px)');
        if(mql.matches){
            animation()
        }else{
            animation()
        }
    }
    useEffect(()=>{
        gsap.registerPlugin(ScrollTrigger)
        mediaquery()
        let i = 1 ;
        i = i - i;
        console.log(i)

    })
    return(
        <div className="third section container">
            <div className="content3">
                <p className="wegather">
                        We gather and organize data, trends and conversations to create,
                        curate and champion the very best stories, ambience, emotion, sound
                        and activity to get customers interested in your brand, products and
                        services:
                </p>
                <div className="tb">
                    <h1><span className="to">
                    <svg viewBox="0 0 129.43 97.95">
                        <defs>
                            <clipPath id="prefix__a" transform="translate(-24.04 -16.7)">
                                <path 
                                transform="rotate(-9.08 82.302 60.776)" fill="none"
                                    d="M6.87 11.28h150.75v99H6.87z" />
                            </clipPath>
                        </defs>
                        <title>{"Asset 5"}</title>
                        <g data-name="Layer 2">
                            <g clipPath="url(#prefix__a)" data-name="Layer 1">
                                <path
                                id="arrowUp"
                                    d="M48.26 56.79h.08l-.18.14s.09-.08.1-.11zM76.49 1.48c-.13 1.15-1 2.68.66 3.59 0 .25.08.49.13.73a1.63 1.63 0 01-1 1.57 2.27 2.27 0 00-1.85 2.17c-1.2 4.63.26 7.92 4.42 10.48 3.87 2.37 7.38 5.36 11.11 8.12-.43.39-.52.55-.64.57-1.14.18-2.29.38-3.44.51a95.36 95.36 0 00-30.42 9c-20 9.53-36.14 24-50.11 41-1.59 1.92-3.71 3.92-2.82 7 .12.44-.49 1.18-.91 1.68C.24 89.55.2 91.55.1 93.54c-.08 1.52-.34 3.45 1 4.16 1.79 1 3-.89 4.07-2.06C16.04 84.19 27.75 73.7 40.96 65.01c7-3.65 14-7.31 20.66-11.62l12.58-3.72 1.94-.3 5-.37c3.35-.84 6.61-1.35 10.08.1 1.67.69 3.88.09 5.84 0a2.59 2.59 0 01.42.58 1.5 1.5 0 011.6.74c-1.54 1-3.11 1.9-4.61 2.91-7 4.71-6.94 4.72-9.5 12.24-.77 2.3-.45 6.55.7 7.1 2.16 1 3.84-.56 5.53-1.44q14.82-7.72 28.89-16.73c2.92-1.87 5.38-3.86 7.06-7.25 1.51-3 1.73-5.77 2-8.93.65-9 .48-9.83-7.25-13.57A358.48 358.48 0 0184.61 3.6 115.22 115.22 0 0079.06.24c-1.31-.72-2.47-.25-2.61 1.15z"
                                    fill="#0d121a" 
                                    style={{
                                        stroke:'black',
                                        fill:'transparent',
                                        strokeWidth:"3px"
                                    }}
                                    />
                            </g>
                        </g>
                    </svg>
                        TO</span> BE IN A&nbsp;
                        <span className="love">
                        <svg viewBox="0 0 362.66 148.9">
                            <title>{"Asset 1"}</title>
                            <g data-name="Layer 2">
                                <path
                                    id="lovelove"
                                    d="M216.4 125.37c8.67-2 17.4-3.6 25.95-5.92 17.2-4.71 34.33-9.8 51.49-14.68 12.35-3.53 24.36-7.92 35.38-14.69 6.39-3.92 12.08-8.5 15.91-15.08 6.7-11.49 6.34-22.12-1.7-31.41-2.44-2.82-6.07-4.63-9-7.05-6.73-5.66-14.09-10.15-22.89-11.87-3.65-.71-7.27-1.78-10.85-2.85-22.19-6.72-45.06-9.4-68.15-9.09-41 .52-81.47 5.48-121.73 13.09a82.86 82.86 0 00-11.08 2.95c-18 6.14-34.93 14.79-51.33 24.41a103.57 103.57 0 00-25.3 21.16 48.27 48.27 0 00-10.43 20.17c-2.4 9.22.13 16.42 7.25 22.54a60.54 60.54 0 0025.51 13 210.56 210.56 0 0060.14 5.64c16-.79 31.87-2.36 47.83-3.64 21-1.6 42.12-3.14 63-6.66zM136.27 8.11C167.55 3.69 198.9.59 230.43 0c25.14-.41 50.06 2.17 74.28 9.4 4.65 1.41 9.35 2.59 14.07 3.9a43.15 43.15 0 0117.71 9.94 12.36 12.36 0 003.3 2c11 4.94 18.85 12.68 21.63 24.76 4.45 19.18-3.48 33.88-17.55 45.72-11.2 9.44-24.63 15.24-38.57 19.45q-30.39 9.08-60.94 17.42c-20.44 5.55-41.36 8.6-62.47 10.35-22.37 1.9-44.74 3.75-67.1 5.38a241.42 241.42 0 01-67.2-4.73c-12.75-2.69-24.42-7.59-34.36-16.24-5.37-4.67-10.25-9.82-12-16.9a36.61 36.61 0 01-1-12.7 55.24 55.24 0 0112.53-29.52A126.09 126.09 0 0148.32 39c16.14-9.14 32.45-18 50.4-23 12.28-3.33 25.04-5.3 37.55-7.89z"
                                    fill="yellow" data-name="Layer 1" 
                                    style={{
                                        strokeWidth:"3px",
                                        stroke:"#fedb00",
                                        fill:'transparent'
                                    }}
                                    />
                            </g>
                        </svg>
                        MOOD
                        </span>
                        </h1>
                    <h1>TO  &nbsp;
                        <span >

                            LOVE
                        </span>, SHOP AND BRAG.</h1>
                </div>
            </div>
        </div>
    )
}

export default ThirdPages