import React from 'react';
import logo from './logo.svg';
import './App.scss';

import FirstPages from './pages/first/first.pages'
import SecondPages from './pages/second/second.pages'
import ThirdPages from './pages/third/third.pages'
import FourthPages from './pages/fourth/fourth.pages'
import FifthPages from './pages/fifth/Fifth.pages'
import SixthPages from './pages/sixth/Sixth.pages'
import SeventhPages from './pages/seventh/seventh.pages'
import EightPages from './pages/eight/Eight.pages'

import KommsFixedPages from './pages/komms/KommsFixed.pages'

function App() {
  return (
    <React.Fragment>
      <div className="App">
      <FirstPages></FirstPages>
      <SecondPages></SecondPages>
      <ThirdPages></ThirdPages>
      <FourthPages></FourthPages>
      <KommsFixedPages></KommsFixedPages>
    <EightPages></EightPages>
      </div>
    </React.Fragment>
  );
}

export default App;
