import React,{useEffect} from 'react'

import FifthPages from '../fifth/Fifth.pages'
import SixthPages from '../sixth/Sixth.pages'
import SeventhPages from '../seventh/seventh.pages'

import gsap from 'gsap'
import {ScrollTrigger} from 'gsap/ScrollTrigger'

const KommsFixedPages = ()=>{
        const animation = () => {
            const tl = gsap.timeline({
                scrollTrigger: {
                    trigger: '.fifth',
                    // endTrigger:'[data-section-6]',
                    pin: '.komms', // pin the trigger element while active
                    start: "top top", // when the top of the trigger hits the top of the viewport
                    end: "+=400", // end after scrolling 500px beyond the start
                    scrub: 1, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar,
                    markers: false,
                    end: 'bottom'
                }
            })
            tl.from('.desc ul li', {
                opacity: 0,
                x: 10,
                stagger: {
                    each: 0.005
                }
            })
            const tl2 = gsap.timeline({
                scrollTrigger: {
                    trigger: '.kommsfix',
                    // endTrigger:'[data-section-6]',
                    pin: '.komms5', // pin the trigger element while active
                    start: "top top", // when the top of the trigger hits the top of the viewport
                    end: "+=400", // end after scrolling 500px beyond the start
                    scrub: 1, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar,
                    markers: false,
                    end: 'bottom'
                }
            })
            const tl3 = gsap.timeline({
                scrollTrigger: {
                    trigger: '.sixth',
                    // endTrigger:'[data-section-6]',
                    pin: '.content6', // pin the trigger element while active
                    start: "top top", // when the top of the trigger hits the top of the viewport
                    end: "+=400", // end after scrolling 500px beyond the start
                    scrub: 1, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar,
                    markers: false,
                    end: 'bottom'
                }
            })
            tl3.from('.box1', {
                    opacity: 0,
                    y: 10,
                    stagger: {
                        each: 0.03
                    }
                })
                .to('#arrowYellow', {
                    fill: 'transparent'
                })
                .to('#arrowYellow', {
                    drawSVG: "0%"
                })
                .to('.box1', {
                    opacity: 0,
                    y: 10,
                    stagger: {
                        each: 0.1
                    }
                })
            const tl4 = gsap.timeline({
                scrollTrigger: {
                    trigger: '.seven',
                    pin: '.seven', // pin the trigger element while active
                    start: "top top", // when the top of the trigger hits the top of the viewport
                    // end: "+=500", // end after scrolling 500px beyond the start
                    scrub: true, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar,
                    // markers: true,
                    end: 'bottom'
                },
            
            })
            tl4
            .from('.tech',{
                opacity:0,
                scale:0.8,
                duration:0.2
            })
            .from('.stack',{
                opacity:0,
                scale:0.8,
                duration:0.2

            })
            .from('.box2', {
                opacity: 0,
                y: 10,
                stagger: {
                    each: 0.5
                }
            })
            .to('.box2', {
                opacity: 1,
                stagger: {
                    each: 1
                }
            })
            .to('.box2', {
                opacity: 0,
                y: 10,
                stagger: {
                    each: 0.1
                }
            }).to('#arrowYellowDown', {
                fill: 'transparent'
            })
            .to('#arrowYellowDown', {
                drawSVG: "0%"
            })

        }
        const animationMobile = ()=>{
            const tl6 = gsap.timeline({
                scrollTrigger: {
                    trigger: '.sixth',
                    // endTrigger:'[data-section-6]',
                    // pin: '.content6', // pin the trigger element while active
                    start: "top 200px", // when the top of the trigger hits the top of the viewport
                    end: "+=50", // end after scrolling 500px beyond the start
                    scrub: 1, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar,
                    markers: false,
                    // end: 'bottom'
                }
            })
            tl6
            .from('#arrowYellow', {
                drawSVG: '0%'
            })
            .from('#arrowYellow', {
                fill: 'transparent'
            })
            const tl7 = gsap.timeline({
                scrollTrigger: {
                    trigger: '.seven',
                    // endTrigger:'[data-section-6]',
                    // pin: '.content6', // pin the trigger element while active
                    start: "top 200px", // when the top of the trigger hits the top of the viewport
                    end: "+=50", // end after scrolling 500px beyond the start
                    scrub: 1, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar,
                    markers: false,
                    // end: 'bottom'
                }
            })
            tl7.from('.tech',{
                opacity:0
            })
            .from('.stack',{
                opacity:0
            })
            .from('#arrowYellowDown',{
                drawSVG:0
            })
            .from('#arrowYellowDown',{
                fill:'transparent'
            })
        }
    const mediaquery = ()=>{
        let mql = window.matchMedia('(min-width: 992px)');
        if(mql.matches){
            animation()
        }else{
            animationMobile()
        }
    }
    useEffect(()=>{
        gsap.registerPlugin(ScrollTrigger)
        mediaquery()
    })
    return(
        <React.Fragment>
            <FifthPages></FifthPages>
            <div className="kommsfix">
                <SixthPages></SixthPages>
            </div>
                <SeventhPages></SeventhPages>

        </React.Fragment>
    )
}

export default KommsFixedPages