import React from 'react'

const UpperDescWord = ({word})=>{
    return (
        <span className="word">
            {word}
        </span>
    )
}

export default UpperDescWord