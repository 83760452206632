import React from 'react'

import UpperDescChar from './upperdescWord.component'

const UpperDesc = ()=>{
    const sentence = 'WE SHOP FOR THINGS AND SERVICES BECAUSE WE WANT TO MAKE OUR LIVES BETTER AND TO FEEL'
    const ArrSentence = sentence.split(" ")
    return(
        <React.Fragment>
            {
                ArrSentence.map((data,index) => {
                    return(
                        <React.Fragment>
                        <UpperDescChar key={index} word={data} />
                        &nbsp;
                        </React.Fragment>
                    )
                })
            }
        </React.Fragment>
    )
}

export default UpperDesc