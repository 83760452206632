import React,{useEffect} from 'react'
import './fifth.styles.scss'
import gsap from 'gsap'
import {ScrollTrigger} from 'gsap/ScrollTrigger'


const AddspaceContent = ()=>{
    let mql = window.matchMedia('(min-width: 992px)');
    if(mql.matches){
        return(
            <div className="content5">
            </div> 
        )
    }else{
        return null
    }
}

const AddspaceSection = ()=>{
    let mql = window.matchMedia('(min-width: 992px)');
    if(mql.matches){
        return(
            <div className="section container">
            </div>
        )
    }else{
        return null
    }
}

const FifthPages = ()=>{

    return(
        <React.Fragment>
        <div className="fifth section container" data-section-5>
            <div className="content5">
                <div className="komms komms5">
                        <span className="char">
                            <h1>K</h1></span>
                        <span className="char">
                            <h1>O</h1></span>
                        <span className="char">
                            <h1>M</h1>
                        </span>
                        <span className="char">
                        <div className="desc">
                                <ul>
                                    <li>
                                        <h4>ARCHITECTURE</h4>
                                    </li>
                                    <li>
                                        <h4>ARTS</h4>
                                    </li>
                                    <li>
                                        <h4>BEAUTY</h4>
                                    </li>
                                    <li>
                                        <h4>CULINARY</h4>
                                    </li>
                                    <li>
                                        <h4>ENTERTAINMENT</h4>
                                    </li>
                                    <li>
                                        <h4>FASHION</h4>
                                    </li>
                                    <li>
                                        <h4>LIFESTYLE</h4>
                                    </li>
                                    <li>
                                        <h4>PUBLISHING</h4>
                                    </li>
                                    <li>
                                        <h4>RETAIL</h4>
                                    </li>
                                </ul>
                            </div>
                            <h1>M</h1>
                            </span>
                        <span className="char">
                            <svg viewBox="0 0 129.43 97.95" id="S">
                                <defs>
                                    <clipPath id="prefix__a" transform="translate(-24.04 -16.7)">
                                        <path transform="rotate(-9.08 82.302 60.776)" fill="none"
                                            d="M6.87 11.28h150.75v99H6.87z" />
                                    </clipPath>
                                </defs>
                                <title>{"Asset 5"}</title>
                                <g data-name="Layer 2">
                                    <g clipPath="url(#prefix__a)" data-name="Layer 1">
                                        <path id="arrowYellow"
                                            d="M48.26 56.79h.08l-.18.14s.09-.08.1-.11zM76.49 1.48c-.13 1.15-1 2.68.66 3.59 0 .25.08.49.13.73a1.63 1.63 0 01-1 1.57 2.27 2.27 0 00-1.85 2.17c-1.2 4.63.26 7.92 4.42 10.48 3.87 2.37 7.38 5.36 11.11 8.12-.43.39-.52.55-.64.57-1.14.18-2.29.38-3.44.51a95.36 95.36 0 00-30.42 9c-20 9.53-36.14 24-50.11 41-1.59 1.92-3.71 3.92-2.82 7 .12.44-.49 1.18-.91 1.68C.24 89.55.2 91.55.1 93.54c-.08 1.52-.34 3.45 1 4.16 1.79 1 3-.89 4.07-2.06C16.04 84.19 27.75 73.7 40.96 65.01c7-3.65 14-7.31 20.66-11.62l12.58-3.72 1.94-.3 5-.37c3.35-.84 6.61-1.35 10.08.1 1.67.69 3.88.09 5.84 0a2.59 2.59 0 01.42.58 1.5 1.5 0 011.6.74c-1.54 1-3.11 1.9-4.61 2.91-7 4.71-6.94 4.72-9.5 12.24-.77 2.3-.45 6.55.7 7.1 2.16 1 3.84-.56 5.53-1.44q14.82-7.72 28.89-16.73c2.92-1.87 5.38-3.86 7.06-7.25 1.51-3 1.73-5.77 2-8.93.65-9 .48-9.83-7.25-13.57A358.48 358.48 0 0184.61 3.6 115.22 115.22 0 0079.06.24c-1.31-.72-2.47-.25-2.61 1.15z"
                                            fill="transparent" style={{
                                        stroke:' #fedb00',
                                        fill:' #fedb00',
                                        strokeWidth:"3px"
                                    }} />
                                    </g>
                                </g>
                            </svg>
                            <h1>S</h1>
                        </span>
                </div>
            </div>
            {/* <div className="content5">

            </div> */}
            {/* <AddspaceContent></AddspaceContent> */}
        </div>
        <AddspaceSection></AddspaceSection>
        </React.Fragment>

    )
}

export default FifthPages