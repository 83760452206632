import React ,{useEffect} from 'react'
import './fourth.styles.scss'
import gsap from 'gsap'
import {ScrollTrigger} from 'gsap/ScrollTrigger'
import webimg from '../fourth/ezgif-7-c728a821beb4.gif'

const FourthPages = ()=>{
    const animation = ()=>{
        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: ".fourth",
                pin: true,   // pin the trigger element while active
                start: "top top", // when the top of the trigger hits the top of the viewport
                end: `+=400`, // end after scrolling 500px beyond the start
                scrub: 1, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar,
                // markers:true,
                // snap: {
                //   snapTo: "labels", // snap to the closest label in the timeline
                //   duration: {min: 0.2, max: 3}, // the snap animation should be at least 0.2 seconds, but no more than 3 seconds (determined by velocity)
                //   delay: 0.2, // wait 0.2 seconds from the last scroll event before doing the snapping
                //   ease: "power1.inOut" // the ease of the snap animation ("power3" by default)
                // }
              }
        })
        const tl2 = gsap.timeline({
            scrollTrigger: {
                trigger: ".App",
                pin: '.mail',   // pin the trigger element while active
                start: "top top", // when the top of the trigger hits the top of the viewport
                end: `bottom`, // end after scrolling 500px beyond the start
                scrub: 1, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar,
                // markers:true,
                // snap: {
                //   snapTo: "labels", // snap to the closest label in the timeline
                //   duration: {min: 0.2, max: 3}, // the snap animation should be at least 0.2 seconds, but no more than 3 seconds (determined by velocity)
                //   delay: 0.2, // wait 0.2 seconds from the last scroll event before doing the snapping
                //   ease: "power1.inOut" // the ease of the snap animation ("power3" by default)
                // }
              }
        })
        tl.from('.growTxt',{
            opacity:0,
            y:'-10px'
        })
        .from('.webimg',{
            opacity:0,
            x:100
        })
        .from('.linedown',{
            opacity:0,
            y:10
        })
        .fromTo('#growingcirc',{
            drawSVG:"0%"
        },{
            drawSVG:"100%",
            fill:'white'
        })
        .fromTo('#linedown',{
            drawSVG:"0%"
        },{
            drawSVG:"100%",
            fill:'white'
        })
    }
    const mediaquery = ()=>{
        let mql = window.matchMedia('(min-width: 992px)');
        if(mql.matches){
            animation()
        }else{
            animation()
        }
    }
    useEffect(()=>{
        gsap.registerPlugin(ScrollTrigger)
        mediaquery()
    })
    return(
        <div className="fourth section container">
            <div className="content4">
                <div className="desc">
                <h1><span className="growTxt">
                Created
                    KRAVELIST.COM
                    in 2012 and is still
                </span>
                   <span className="grow">
                   <svg viewBox="0 0 362.66 148.9">
                            <title>{"Asset 1"}</title>
                            <g data-name="Layer 2">
                                <path
                                    id="growingcirc"
                                    d="M216.4 125.37c8.67-2 17.4-3.6 25.95-5.92 17.2-4.71 34.33-9.8 51.49-14.68 12.35-3.53 24.36-7.92 35.38-14.69 6.39-3.92 12.08-8.5 15.91-15.08 6.7-11.49 6.34-22.12-1.7-31.41-2.44-2.82-6.07-4.63-9-7.05-6.73-5.66-14.09-10.15-22.89-11.87-3.65-.71-7.27-1.78-10.85-2.85-22.19-6.72-45.06-9.4-68.15-9.09-41 .52-81.47 5.48-121.73 13.09a82.86 82.86 0 00-11.08 2.95c-18 6.14-34.93 14.79-51.33 24.41a103.57 103.57 0 00-25.3 21.16 48.27 48.27 0 00-10.43 20.17c-2.4 9.22.13 16.42 7.25 22.54a60.54 60.54 0 0025.51 13 210.56 210.56 0 0060.14 5.64c16-.79 31.87-2.36 47.83-3.64 21-1.6 42.12-3.14 63-6.66zM136.27 8.11C167.55 3.69 198.9.59 230.43 0c25.14-.41 50.06 2.17 74.28 9.4 4.65 1.41 9.35 2.59 14.07 3.9a43.15 43.15 0 0117.71 9.94 12.36 12.36 0 003.3 2c11 4.94 18.85 12.68 21.63 24.76 4.45 19.18-3.48 33.88-17.55 45.72-11.2 9.44-24.63 15.24-38.57 19.45q-30.39 9.08-60.94 17.42c-20.44 5.55-41.36 8.6-62.47 10.35-22.37 1.9-44.74 3.75-67.1 5.38a241.42 241.42 0 01-67.2-4.73c-12.75-2.69-24.42-7.59-34.36-16.24-5.37-4.67-10.25-9.82-12-16.9a36.61 36.61 0 01-1-12.7 55.24 55.24 0 0112.53-29.52A126.09 126.09 0 0148.32 39c16.14-9.14 32.45-18 50.4-23 12.28-3.33 25.04-5.3 37.55-7.89z"
                                    fill="yellow" data-name="Layer 1" 
                                    style={{
                                        strokeWidth:"3px",
                                        stroke:"white",
                                        fill:'transparent'
                                    }}
                                    />
                            </g>
                        </svg>
                   <span className="growTxt">
                   &nbsp;growing it
                </span>
                    </span> 
                </h1>
                <p className="linedown">
                                A motley crew of globe-trotting
                culture vultures, story tellers and
                geeks bounded by our love to shop.
                <svg viewBox="0 0 269.91 26.22">
                    <title>{"Asset 8"}</title>
                    <g data-name="Layer 2">
                        <path
                        id="linedown"
                            d="M167.7 0h68.05a154.94 154.94 0 0131.41 3.58 10.68 10.68 0 012.75 1.53c-.78.74-1.39 1.75-2.31 2.18a69.36 69.36 0 01-27.66 6.29c-16.66.31-33.33.22-49.95.53-30.54.57-61.07.87-91.61 2.09-14.14.57-28.18 2.93-42.28 4.63-13.1 1.62-26.17 3.59-39.3 5.11a42.44 42.44 0 01-11.26-.21C1.88 25.2-.08 22.58 0 19.83s2-4.59 5.85-5.29c17.06-3 33.94-6.85 51.26-8.25 11.82-1 23.6-3.06 35.42-4.15 8.68-.83 17.45-1 26.22-1.22C136.33.52 154 .26 167.7 0z"
                            fill="#fff" data-name="Layer 1" 
                            style={{
                                strokeWidth:"3px",
                                stroke:"white",
                                fill:'transparent'
                            }}/>
                    </g>
                </svg>
                </p>
                </div>
                <div className="webimg">
                    <img src={webimg} alt="" srcset=""/>
                </div>
            </div>
        </div>
    )
}

export default FourthPages