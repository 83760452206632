import React,{useEffect} from 'react'
import './Eight.styles.scss'
import gsap from 'gsap'
import {ScrollTrigger} from 'gsap/ScrollTrigger'

const EightPages = ()=>{
    const animation = () => {
        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: '.eigth',
                // endTrigger:'[data-section-6]',
                pin: '.eigth', // pin the trigger element while active
                start: "top top", // when the top of the trigger hits the top of the viewport
                end: "+=400", // end after scrolling 500px beyond the start
                scrub: 0.5, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar,
                markers: false,
                end: 'bottom'
            }
        }).from('.sh',{
            opacity:0
        }).from('.gt',{
            opacity:0
        })
        .fromTo('#email',{
            drawSVG:"0%",
        },{
            drawSVG:"100%",
        })
        .fromTo('#email',{
            fill:'transparent'
        },{
            fill:'black'
        })
    }
    const mediaquery = ()=>{
        let mql = window.matchMedia('(min-width: 992px)');
        if(mql.matches){
            animation()
        }else{
            animation()
        }
    }
    useEffect(()=>{
        gsap.registerPlugin(ScrollTrigger)
        mediaquery()
    })
    return(
        <div className="eigth section container">
            <div className="content8">
                <div className="sh">
                    <h1>SHALL WE?</h1>
                    <div className="gt">
                        <h1>
                            get in touch,
                        </h1>
                        <h1><a href="mailto: hi@komms.co"> hi@komms.co</a></h1>
                        <svg viewBox="0 0 269.91 26.22" >
                            <title>{"Asset 2"}</title>
                            <g data-name="Layer 2">
                                <path
                                id="email"
                                    d="M167.7 0h68.05a154.94 154.94 0 0131.41 3.58 10.68 10.68 0 012.75 1.53c-.78.74-1.39 1.75-2.31 2.18a69.36 69.36 0 01-27.66 6.29c-16.66.31-33.33.22-49.95.53-30.54.57-61.07.87-91.61 2.09-14.14.57-28.18 2.93-42.28 4.63-13.1 1.62-26.17 3.59-39.3 5.11a42.44 42.44 0 01-11.26-.21C1.88 25.2-.08 22.58 0 19.83s2-4.59 5.85-5.29c17.06-3 33.94-6.85 51.26-8.25 11.82-1 23.6-3.06 35.42-4.15 8.68-.83 17.45-1 26.22-1.22C136.33.52 154 .26 167.7 0z"
                                    fill="#0d121a" data-name="Layer 1" />
                            </g>
                        </svg>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EightPages