import React,{useEffect} from 'react'
import gsap from 'gsap'
import {DrawSVGPlugin} from 'gsap/DrawSVGPlugin'

const HappierCircle = (props)=>{
    useEffect(()=>{
        console.log('hehehe')
        gsap.registerPlugin(DrawSVGPlugin);
        const tl = gsap.timeline()
        // tl.


    })
    return(
        <svg viewBox="0 0 362.66 148.9" {...props} style={{width:'4.5em',position:'absolute',top:'-0.5em',left:'-0.6em'}}>
        <title>{"Asset 1"}</title>
        <g data-name="Layer 2">
            <path
                id="circleHappier"
                strokeWidth="4"
                d="M216.4 125.37c8.67-2 17.4-3.6 25.95-5.92 17.2-4.71 34.33-9.8 51.49-14.68 12.35-3.53 24.36-7.92 35.38-14.69 6.39-3.92 12.08-8.5 15.91-15.08 6.7-11.49 6.34-22.12-1.7-31.41-2.44-2.82-6.07-4.63-9-7.05-6.73-5.66-14.09-10.15-22.89-11.87-3.65-.71-7.27-1.78-10.85-2.85-22.19-6.72-45.06-9.4-68.15-9.09-41 .52-81.47 5.48-121.73 13.09a82.86 82.86 0 00-11.08 2.95c-18 6.14-34.93 14.79-51.33 24.41a103.57 103.57 0 00-25.3 21.16 48.27 48.27 0 00-10.43 20.17c-2.4 9.22.13 16.42 7.25 22.54a60.54 60.54 0 0025.51 13 210.56 210.56 0 0060.14 5.64c16-.79 31.87-2.36 47.83-3.64 21-1.6 42.12-3.14 63-6.66zM136.27 8.11C167.55 3.69 198.9.59 230.43 0c25.14-.41 50.06 2.17 74.28 9.4 4.65 1.41 9.35 2.59 14.07 3.9a43.15 43.15 0 0117.71 9.94 12.36 12.36 0 003.3 2c11 4.94 18.85 12.68 21.63 24.76 4.45 19.18-3.48 33.88-17.55 45.72-11.2 9.44-24.63 15.24-38.57 19.45q-30.39 9.08-60.94 17.42c-20.44 5.55-41.36 8.6-62.47 10.35-22.37 1.9-44.74 3.75-67.1 5.38a241.42 241.42 0 01-67.2-4.73c-12.75-2.69-24.42-7.59-34.36-16.24-5.37-4.67-10.25-9.82-12-16.9a36.61 36.61 0 01-1-12.7 55.24 55.24 0 0112.53-29.52A126.09 126.09 0 0148.32 39c16.14-9.14 32.45-18 50.4-23 12.28-3.33 25.04-5.3 37.55-7.89z"
                fill="#0d121a" data-name="Layer 1" style={{strokeDashoffset: '0',
                strokeDasharray: 'none',
                fill: 'transparent',
                strokeWidth: '4px'}}/>
        </g>
    </svg>
    )
}

export default HappierCircle