import React,{useEffect} from 'react'
import './second.styles.scss'
import gsap from 'gsap'
import {ScrollTrigger} from 'gsap/ScrollTrigger'



const SecondPages = (props)=>{
    const animation = ()=>{
        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: ".second",
                pin: true,   // pin the trigger element while active
                start: "top top", // when the top of the trigger hits the top of the viewport
                end: `+=400`, // end after scrolling 500px beyond the start
                scrub: 1, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar,
                // markers:true,
                // snap: {
                //   snapTo: "labels", // snap to the closest label in the timeline
                //   duration: {min: 0.2, max: 3}, // the snap animation should be at least 0.2 seconds, but no more than 3 seconds (determined by velocity)
                //   delay: 0.2, // wait 0.2 seconds from the last scroll event before doing the snapping
                //   ease: "power1.inOut" // the ease of the snap animation ("power3" by default)
                // }
              }
        })
        tl.from('.we',{opacity:0.2,y:"-10px",stagger:{
            each:0.3
        }})
        .from('.th',{opacity:0,stagger:{
            each:0.3
        }})
        .fromTo('#ml',{
            drawSVG:"0%",
            fill:'transparent',
        },{
            drawSVG:"100%",
            fill:'black',
            fillRule:'nonzero'
        })
        .fromTo('#arrowSell',{
            drawSVG:"0%",
            fill:'transparent',
        },{
            drawSVG:"100%",
            fill:'black',
            fillRule:'nonzero'
        })
    }
    const mediaquery = ()=>{
        let mql = window.matchMedia('(min-width: 992px)');
        if(mql.matches){
            animation()
        }else{
            animation()
        }
    }
    useEffect(()=>{
        gsap.registerPlugin(ScrollTrigger)
        mediaquery()
       
    })
    return(
        <div className="second section container">
            <div className="content2">
                <h1><span className="we">WE</span>&nbsp;<span className="we">MAKE</span>&nbsp;<span className="we">YOU</span>&nbsp;<span className="we">HAPPIER</span>&nbsp;<span className="we">BY</span> &nbsp;<span className="mv we">MOVING
                        <svg viewBox="0 0 269.91 26.22" {...props}
                            style={{position:'absolute',top:'74%',width:'90%',left:'10%'}}>
                            <title>{"Asset 2"}</title>
                            <g data-name="Layer 2">
                                <path
                                id="ml"
                                    d="M167.7 0h68.05a154.94 154.94 0 0131.41 3.58 10.68 10.68 0 012.75 1.53c-.78.74-1.39 1.75-2.31 2.18a69.36 69.36 0 01-27.66 6.29c-16.66.31-33.33.22-49.95.53-30.54.57-61.07.87-91.61 2.09-14.14.57-28.18 2.93-42.28 4.63-13.1 1.62-26.17 3.59-39.3 5.11a42.44 42.44 0 01-11.26-.21C1.88 25.2-.08 22.58 0 19.83s2-4.59 5.85-5.29c17.06-3 33.94-6.85 51.26-8.25 11.82-1 23.6-3.06 35.42-4.15 8.68-.83 17.45-1 26.22-1.22C136.33.52 154 .26 167.7 0z"
                                    fill="transparent" data-name="Layer 1" style={{fill: 'transparent',
                                        stroke: 'black',
                                        strokeWidth: '5px',}}/>
                            </g>
                        </svg></span>&nbsp;<span className="we">YOU</span>&nbsp;<span className="we">FROM</span></h1>
                <h1 className="hw hw2 th-container">
                    <span>
                   <span className="th">thinking</span>&nbsp;<span className="th">to</span>&nbsp;<span className="th">knowing</span>&nbsp;
                    </span>
                    <br/>
                    <span>
                        <span className="th">what</span>&nbsp;
                        <span className="th">to</span>&nbsp;
                     <span className="hw th">create <svg viewBox="0 0 106.03 60.04" {...props} style={{width: '2.5em',
    position: 'absolute',
    left: '3em',
    top: '1em'}}>
                        <title>{"Asset 4"}</title>
                        <g data-name="Layer 2">
                            <path
                            id="arrowSell"
                                d="M81.3 33.55c-1.36.14-2.5.32-3.65.36-3.82.12-7.64.38-11.45.21-4.11-.19-7-3.78-7-8 0-1.06-.79-2.1-1.28-3.26.47-1.24 1.7-.84 2.7-.86 9.07-.12 17.83-2.08 26.39-4.82a75.47 75.47 0 009.5-4.18c2.45-1.17 2.79-1.34 4.66.69 3.34 3.62 5.35 7.74 4.75 12.94-1 9-1.52 18.14-4.14 26.92-.43 1.43-1 2.8-1.87 5-2.47-3.25-4.91-5.48-4.31-9.28.38-2.32.71-4.65 1.21-7.83-2.25 1.75-3.77 3-5.34 4.17C79.68 54.49 66.5 60.15 51.68 60c-13.49 0-26.36-3.38-36.79-12.78C6.13 39.32 1.83 29.05.37 17.59-.21 13 .06 8.28.08 3.63A16.63 16.63 0 01.85 0h1c.46 1.64 1 3.27 1.32 4.94a15.81 15.81 0 010 3.23C2.91 22 9.54 31.91 21.1 38.74c8.82 5.26 18.57 6.87 28.71 6.16a65.93 65.93 0 0029.78-9.64 9.83 9.83 0 001.18-.81 4.16 4.16 0 00.53-.9z"
                                fill="#0d121a" data-name="Layer 1" style={{fill: 'transparent',
                                stroke: 'black',
                                strokeWidth: '5px',}}/>
                        </g>
                    </svg></span><span className="th">, to</span>&nbsp;<span className="th">sell</span> 
                    </span>
                    <br />
                    
                </h1>

            </div>
        </div>
    )
}

export default SecondPages