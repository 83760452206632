import React,{useEffect} from 'react'
import './sixth.styles.scss'
import gsap from 'gsap'
import {ScrollTrigger} from 'gsap/ScrollTrigger'

const SixthPages = ()=>{
    return(
        <React.Fragment>
            <div className="sixth section container" data-section-6>
                <div className="content6">
                    <div className="box1 box">
                        <h1>01.</h1>
                        <h1>CONSULTING</h1>
                        <p>
                        <ul>
                            <li>Brand and Product Development</li>
                            <li>GTM Strategy</li>
                            <li style={{fontSize:'0.7em',display:'flex',flexDirection:'row',justifyContent:'start',marginTop:0}}>
                                {/* (Product, People, Promotion, Place, Price,<br></br> Purpose, Pride, Partnership,Protection and
                                Personalization) */}
                                <ul className="ul_gtm">
                                    <li>Product</li>
                                    <li>People</li>
                                    <li>Promotion</li>
                                    <li>Place</li>
                                    <li>Price</li>
                                </ul>
                                <ul className="ul_gtm">
                                    <li>Purpose</li>
                                    <li>Pride</li>
                                    <li>Partnership</li>
                                    <li>Protection</li>
                                    <li>Personalization</li>
                                </ul>
                                </li>
                            <li>Partnership and Collaborations</li>
                        </ul>
                        </p>
                    </div>
                    <div className="box1 box">
                        <h1>02.</h1>
                        <h1>INSIGHTS</h1>
                        <p>
                        <ul>
                            <li>Social Listening</li>
                            <li>Search Aggregation</li>
                            <li>Market & Cultural Trends</li>
                            <li>Competitor Analysis</li>
                            <li>Influencers' Assessment</li>
                        </ul>
                        </p>
                    </div>
                    <div className="box1 box">
                        <h1>03.</h1>
                        <h1>COMMUNICATIONS</h1>
                        <p>
                        <ul>
                            <li>Reputation Management</li>
                            <li>Brand Messaging & Story-selling</li>
                            <li>Media Strategy, Relations and Partnership</li>
                        </ul>
                        </p>
                    </div>
                    <div className="box1 box">
                        <h1>04.</h1>
                        <h1>CREATIVE</h1>
                        <p>
                        <ul>
                            <li>Personality and Identity</li>
                            <li>Visual Language</li>
                            <li>Content Generation</li>
                        </ul>
                        </p>
                    </div>
                    <div className="box1 box">
                        <h1>05.</h1>
                        <h1>DIGITAL & SOCIAL</h1>
                        <p>
                        <ul>
                            <li>
                            Multi Platform Integrated Strategies</li>
                            <li>
                            Paid & Organic Reactive Amplification
                            Programmes
                            </li>
                            <li>Thought-Leader Partnership</li>
                        </ul>
                        </p>
                    </div>
                    <div className="box1 box">
                        <h1>06.</h1>
                        <h1>FUND & FUNDING</h1>
                        <p>
                        <ul>
                            <li>Micro Credit</li>
                            <li>Investors</li>
                        </ul>
                        </p>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default SixthPages