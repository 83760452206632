import React,{useEffect} from 'react'
import './first.styles.scss'
import HappierCircle from '../../components/first/happier.svg'

import UpperDesc from '../../components/first/upperdesc.component'

import gsap from 'gsap'
import {DrawSVGPlugin} from 'gsap/DrawSVGPlugin'
import {ScrollTrigger} from 'gsap/ScrollTrigger'

const FirstPages = (props)=>{
    useEffect(()=>{
        const tl = gsap.timeline()
        gsap.registerPlugin(DrawSVGPlugin);

        tl.from(".word",{
            opacity:0.3,
            y:'10px',
            stagger:{
                each:0.02
            }
        }).from(".happier",{
            opacity:0,
            y:'10px',
        }).fromTo('#circleHappier',{
            drawSVG:"0%",
            stroke: 'black',
        },{
            drawSVG:"100%",
            stroke: 'black',
            fill:'black',
            duration:1,
            delay:.2
        })
        .from(".thatsit",
        {opacity:0,
        y:'10px',}).fromTo('#thatsLine',{
            drawSVG:"0%",
            stroke: 'black',
            fill:'transparent',

        },{
            drawSVG:"100%",
            stroke: 'black',
            fill:'black',
            duration:0.5,
            delay:.5
        })

    })
    return(
        <div className="first section container">
            <div className="content">
                <a className="mail" href="mailto:hi@komms.co">
                    <img src="https://i.ibb.co/kMt3d36/Mail-Button-removebg-preview.png" alt="" srcset=""/>
                </a>
                <h1>
                    {/* <span>
                WE SHOP FOR THINGS AND
                    SERVICES BECAUSE WE WANT
                    TO MAKE OUR LIVES BETTER
                    AND TO FEEL  &nbsp;
                    </span>  */}
                    <UpperDesc/>
                    <span className="hw happier">
                    <HappierCircle/>
                    <span>
                    happier
                    </span>
                    </span></h1> 
            <h1 className="hw thatsit">THAT'S IT.
            <svg viewBox="0 0 269.91 26.22" {...props} style={{position:'absolute',top:'108%',width:'90%',left:'10%'}}>
                <title>{"Asset 2"}</title>
                <g data-name="Layer 2">
                    <path
                        id="thatsLine"
                        d="M167.7 0h68.05a154.94 154.94 0 0131.41 3.58 10.68 10.68 0 012.75 1.53c-.78.74-1.39 1.75-2.31 2.18a69.36 69.36 0 01-27.66 6.29c-16.66.31-33.33.22-49.95.53-30.54.57-61.07.87-91.61 2.09-14.14.57-28.18 2.93-42.28 4.63-13.1 1.62-26.17 3.59-39.3 5.11a42.44 42.44 0 01-11.26-.21C1.88 25.2-.08 22.58 0 19.83s2-4.59 5.85-5.29c17.06-3 33.94-6.85 51.26-8.25 11.82-1 23.6-3.06 35.42-4.15 8.68-.83 17.45-1 26.22-1.22C136.33.52 154 .26 167.7 0z"
                        fill="#0d121a" data-name="Layer 1" style={{strokeDashoffset: '0',
                        strokeDasharray: 'none',
                        fill: 'transparent',
                        strokeWidth: '4px',stroke:'black'}}/>
                </g>
            </svg>
            </h1>
            </div>
        </div>
    )
}

export default FirstPages